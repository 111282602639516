import * as React from 'react';
import {Link} from 'gatsby';
import {Footer} from '../parts/footer';
import {Helmet} from 'react-helmet';
import {Wave} from '../parts/wave';

// markup
const NotFoundPage = () => {
  return (
    <main className="page-wrapper">
      <Helmet>
        <meta name="description" content="Kaloczi David's personal site."/>
        <title>David Kaloczi</title>
      </Helmet>
      <div className="page-section">

        <div className="page-main">
          <h1>Uhh</h1>
          <h3>Sorry, I can't answer this 😔</h3>
          <p>
            This page is not there. <br/>
            I can get you <Link to="/">home</Link>.
          </p>
        </div>
        <Wave waveNumber={3}/>
      </div>


      <Footer/>
    </main>
  );
};

export default NotFoundPage;
